import { COUNTRIES_DEFAULT } from '@qlevr/shared/constants';

/**
 * Retrieves the country code from a given country locale.
 *
 * @param locale The country locale string.
 * @returns The country code extracted from the locale.
 * @example nl-BE => BE
 * @example nl-NL => NL
 */
export function getCountryFromCountryLocale(locale?: string): string | null {
  if (!locale) return null;
  const countryDefault = COUNTRIES_DEFAULT.toUpperCase();
  const splitCountryLocale = locale.split('-');

  if (splitCountryLocale.length < 2) return countryDefault;

  return splitCountryLocale[1];
}
