import { COUNTRIES, COUNTRIES_DEFAULT } from '@qlevr/shared/constants';
import { CountryCodeShopify } from '@qlevr/shared/schema';

export function getCountryCodeShopify(country?: string | null): CountryCodeShopify {
  if (!country) {
    country = COUNTRIES_DEFAULT;
  }

  const formattedCountry = country.toUpperCase();

  const countryCodeMapping = new Map<string, CountryCodeShopify>([
    [COUNTRIES[0], CountryCodeShopify.NlShopify],
    [COUNTRIES[1], CountryCodeShopify.BeShopify],
    [COUNTRIES[2], CountryCodeShopify.FrShopify],
    [COUNTRIES[3], CountryCodeShopify.DeShopify],
    [COUNTRIES[4], CountryCodeShopify.EsShopify],
    [COUNTRIES[5], CountryCodeShopify.ItShopify],
  ]);

  const countryCode = countryCodeMapping.get(formattedCountry);
  if (!countryCode) {
    return CountryCodeShopify.BeShopify;
  }

  return countryCode;
}
