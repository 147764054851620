// IMPORTANT when update the order, also update the order here => getLanguageCodeShopify()
export const LOCALES = ['en', 'de', 'fr', 'nl', 'es', 'it'] as const;

// IMPORTANT when update the order, also update the order here => getCountryCodeShopify()
export const COUNTRIES = ['NL', 'BE', 'FR', 'DE', 'ES', 'IT'] as const;
type ObjectValues<T> = T[keyof T];
export type CountriesType = ObjectValues<typeof COUNTRIES>;

export const LOCALES_COUNTRIES = ['nl-be', 'fr-be', 'fr-fr', 'nl-nl', 'de-de', 'es-es', 'it-it' ,'en'] as const;
export const LOCALES_DEFAULT = 'en';
export const COUNTRIES_DEFAULT = 'be';

export type LocalesCountriesType = ObjectValues<typeof LOCALES_COUNTRIES>;

// Define base-regional pairs centrally for easier management and extension
export const LOCALISED_PAIRS: { [key: string]: string } = {
  'nl-nl': 'nl-be',
  'fr-fr': 'fr-be',
};
